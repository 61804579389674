import Vue from 'vue'

// initial state
const state = {
  files: {},
  intervals: {},
  activeJobs: {},
}

// getters
const getters = {
  file(state) {
    return (portfolioId, key) => state.files?.[portfolioId]?.[key]
  },
  hasActiveJob: (state) => (portfolioId, jobKey) => {
    return state.activeJobs[`${portfolioId}-${jobKey}`] === true
  },
}

// actions
const actions = {
  setFile({ commit }, { portfolioId, key, file }) {
    commit('setFile', { portfolioId, key, file })
  },
  startJobProgress({ commit, state }, { portfolioId, jobKey, callback, interval = 2000 }) {
    const key = `${portfolioId}-${jobKey}`

    // Clear existing interval if any
    if (state.intervals[key]) {
      clearInterval(state.intervals[key])
    }

    // Start new interval
    const intervalId = setInterval(callback, interval)
    commit('setInterval', { key, intervalId })
    commit('setActiveJob', { key, active: true })
  },
  stopJobProgress({ commit, state }, { portfolioId, jobKey }) {
    const key = `${portfolioId}-${jobKey}`

    if (state.intervals[key]) {
      clearInterval(state.intervals[key])
      commit('setInterval', { key, intervalId: null })
      commit('setActiveJob', { key, active: false })
    }
  },
}

// mutations
const mutations = {
  setFile(state, { portfolioId, key, file }) {
    if (file) {
      state.files = { ...state.files, [portfolioId]: { ...state.files[portfolioId], [key]: file } }
    } else if (state.files[portfolioId]) {
      delete state.files[portfolioId][key]
    }
  },
  setInterval(state, { key, intervalId }) {
    Vue.set(state.intervals, key, intervalId)
  },
  setActiveJob(state, { key, active }) {
    Vue.set(state.activeJobs, key, active)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
